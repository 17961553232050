module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#000","crossOrigin":"use-credentials","display":"standalone","icon":"src/favicon/icon.png","name":"Liz Shigetoshi","short_name":"Liz Shigetoshi","start_url":"/","theme_color":"#000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"c9893af03a4346301d7313581ca17115"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
